import { Box, Button, Checkbox, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { apiUrl, useApiRequest } from '../store/Common'
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { useCommonUI } from '../context/UI';
import moment from 'moment';
import { AssignmentInd, ElectricMoped, Note, SearchTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function SchedulePage() {

    const {fetchData, postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();
    const [isLoading, setIsLoading] = useState(false);
    const [pickups, setPickups] = useState([]);
    const [deliveries, setDeliveries] = useState([]);
    const [pickupItems, setPickupItems] = useState([]);
    const [deliveryItems, setDeliveryItems] = useState([]);
    const [riders, setRiders] = useState([]);
    const [selectedRiders, setSelectedRiders] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [store, setStore] = useState('');
    const [selectedStore, setSelectedStore] = useState('');
    const [storeList, setStoreList] = useState([]);

    const [activeRiders, setActiveRiders] = useState([]);
    const [selectedActiveRider, setSelectedActiveRider] = useState('');
    const [pickupModal, setPickupModal] = useState(false);
    const [pickupSubmitting, setPickupSubmitting] = useState(false);
    const [pickupLoading, setPickupLoading] = useState(false);
    const [pickupOrder, setPickupOrder] = useState(null);

    const [allRiders, setAllRiders] = useState([]);
    const [selectedQueueRider, setSelectedQueueRider] = useState('');
    const [queueModal, setQueueModal] = useState(false);
    const [queueSubmitting, setQueueSubmitting] = useState(false);
    const [queueLoading, setQueueLoading] = useState(false);
    const [queueOrder, setQueueOrder] = useState(null);
    const [queueOrderType, setQueueOrderType] = useState(null);
    const [timeList, setTimeList] = useState([]);
    const [selectedTime, setSelectedTime] = useState('');

    const [noteModal, setNoteModal] = useState(false);
    const [submittingNote, setSubmittingNote] = useState(false);
    const [note, setNote] = useState('');
    const [noteId, setNoteId] = useState(null);

    const getData = async(store) =>{
        if(store === ""){
            return;
        }
        setIsLoading(true);
        setDeliveries([]);
        setPickups([]);
        setRiders([]);
        setSelectedRiders([]);
        setDeliveryItems([]);
        setPickupItems([]);
        const resp = await fetchData('schedule/list/'+store);
        if(resp){
            setPickups(resp.pickups);
            setDeliveries(resp.deliveries);
        }
        const resp1 = await fetchData('schedule/riders/'+store);
        if(resp1){
            setRiders(resp1.data);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        const getStore = async() =>{
            const resp1 = await fetchData('store/listcenter');
            if(resp1){
                setStoreList(resp1.data);
            }
        }
        getStore();
    },[fetchData]);
    
    const submitStore = (e) =>{
        e.preventDefault();
        setStore(selectedStore);
        getData(selectedStore);
    }

    const handlePickup = (itemId) =>{
        setPickupItems((prevPickupItems) => {
            if (prevPickupItems.includes(itemId)) {
                return prevPickupItems.filter((id) => id !== itemId);
            } else {
                return [...prevPickupItems, itemId];
            }
        });
    }
    const handleDelivery = (itemId) =>{
        setDeliveryItems((prevPickupItems) => {
            if (prevPickupItems.includes(itemId)) {
                return prevPickupItems.filter((id) => id !== itemId);
            } else {
                return [...prevPickupItems, itemId];
            }
        });
    }
    const handleRider = (itemId) =>{
        setSelectedRiders((prevPickupItems) => {
            if (prevPickupItems.includes(itemId)) {
                return prevPickupItems.filter((id) => id !== itemId);
            } else {
                return [...prevPickupItems, itemId];
            }
        });
    }

    const readyAssign = async() =>{
        try{
            if(selectedRiders.length === 0){
                throw new Error('No Riders Selected');
            }
            if(pickupItems.length === 0 && deliveryItems.length === 0){
                throw new Error('No Address Selected');
            }

            setIsSubmitting(true);
            const resp = await postData('schedule/assign/'+store, {riders: selectedRiders, pickups: pickupItems, deliveries: deliveryItems});
            if(resp){
                openSnackbar('Jobs Assigned', "success");
                getData(store);
            }
            setIsSubmitting(false);
        }catch(e){
            openSnackbar(e.message, "error");
        }
    }

    const openPickupModal = async(order) =>{
        setSelectedActiveRider('');
        setActiveRiders([]);
        setPickupOrder(order.id);
        setPickupModal(true);
        setPickupLoading(true);
        const resp = await fetchData('schedule/activeriders/'+store);
        if(resp){
            setActiveRiders(resp.data);
        }
        setPickupLoading(false);
    }

    const submitPickup = async() =>{
        if(selectedActiveRider === "" || !pickupOrder){
            return;
        }

        setPickupSubmitting(true);
        const resp = await postData('schedule/addpickup', {riderId: selectedActiveRider, orderId: pickupOrder});
        if(resp){
            setPickupItems((prevPickupItems) => {
                return prevPickupItems.filter((id) => id !== pickupOrder);
            });
            setPickups((prevPickups)=>{
                return prevPickups.filter((item) => item.id !== pickupOrder);
            })
            setPickupModal(false);
        }
        setPickupSubmitting(false);
    }

    const pickupModalClose = () =>{
        if(pickupSubmitting){
            return;
        }
        setPickupModal(false);
    }

    const generateTimeOptions = () => {
        const options = [];
        const now = new Date();
        let startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0);
      
        // If current time is past 8 PM, start from 8 AM the next day
        if (now.getHours() >= 20) {
            startDate.setDate(startDate.getDate() + 1);
        } else if (now.getHours() >= 8) {
            // If current time is between 8 AM and 8 PM, start from the next hour
            startDate.setHours(now.getHours() + 1);
        }
      
        // Generate options for today and tomorrow between 8 AM and 8 PM
        for (let dayOffset = 0; dayOffset < 2; dayOffset++) {
            for (let hour = startDate.getHours(); hour <= 20; hour++) {
                const currentDateTime = new Date(startDate);
                currentDateTime.setHours(hour);
        
                const epochTime = currentDateTime.getTime();
                const formattedDateTime = moment(currentDateTime).format('Do MMM - h a');
        
                options.push({ value: epochTime, name: formattedDateTime });
            }
            // Reset start hour for the next day
            startDate.setDate(startDate.getDate() + 1);
            startDate.setHours(8);
        }
      
        return options;
    };

    const openQueueModal = async(order, type) =>{
        setSelectedQueueRider('');
        setAllRiders([]);
        setQueueOrder(order.id);
        setQueueOrderType(type);
        setTimeList(generateTimeOptions());
        setSelectedTime('');
        setQueueModal(true);
        setQueueLoading(true);
        const resp = await fetchData('schedule/allriders/'+store);
        if(resp){
            setAllRiders(resp.data);
        }
        setQueueLoading(false);
    }
    const submitQueue = async() =>{
        if(selectedQueueRider === "" || !queueOrder || selectedTime === ""){
            return;
        }

        setQueueSubmitting(true);
        const resp = await postData('schedule/queue', {rider: selectedQueueRider, order: queueOrder, date: selectedTime, type: queueOrderType});
        if(resp){
            if(queueOrderType === "P"){
                setPickupItems((prevPickupItems) => {
                    return prevPickupItems.filter((id) => id !== queueOrder);
                });
                setPickups((prevPickups)=>{
                    return prevPickups.filter((item) => item.id !== queueOrder);
                })
            }else if(queueOrderType === "D"){
                setDeliveryItems((prevPickupItems) => {
                    return prevPickupItems.filter((id) => id !== queueOrder);
                });
                setDeliveries((prevPickups)=>{
                    return prevPickups.filter((item) => item.id !== queueOrder);
                })
            }
            setQueueModal(false);
        }
        setQueueSubmitting(false);
    }

    const queueModalClose = () =>{
        if(queueSubmitting){
            return;
        }
        setQueueModal(false);
    }

    const openNoteModal = (item) =>{
        setNote(item.deliveryNote);
        setNoteId(item.id)
        setNoteModal(true);
    }
    const closeNoteModal = () =>{
        if(submittingNote){
            return;
        }
        setNoteModal(false);
    }

    const updateNote = async() =>{
        setSubmittingNote(true);
        const resp = await postData('schedule/deliverynote',{orderId: noteId, note: note});
        if(resp){
            setNoteModal(false);
            openSnackbar('Note Updated', 'success');
            getData(selectedStore);
        }
        setSubmittingNote(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onSubmit={submitStore}>
                <Stack flexDirection='row' gap={2}>
                    <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select value={selectedStore} onChange={(e)=>{setSelectedStore(e.target.value)}} >
                            {storeList.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading List</Typography>
            </Stack>}
            <Stack mb={2} padding={2}>
                <Typography mb={3} variant='h5'>Riders</Typography>
                <Stack gap={4} flexDirection={'row'} flexWrap={'wrap'}>
                    {riders.map(rider=>{
                        return <Stack key={rider.id} flexDirection={'row'} sx={{padding: 2, gap: 4, background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005', alignItems: 'center', width:'49%'}}>
                            <Stack justifyContent={'center'}>
                                <Box>
                                    <Checkbox checked={selectedRiders.includes(rider.id)} onChange={()=>{handleRider(rider.id)}} />
                                </Box>
                            </Stack>
                            <Stack flexDirection={'row'} sx={{justifyContent: 'space-evenly', flex: 1}}>
                                <Typography>{rider.name}</Typography>    
                                <Typography>{rider.phone}</Typography>    
                            </Stack>
                        </Stack>
                    })}
                </Stack>
                {!isLoading && riders.length === 0 && <Stack>
                    <Typography>No Riders Available</Typography>
                </Stack>}
            </Stack>
            <Stack flexDirection={'row'}>
                <Stack flex={1} padding={2}>
                    <Typography mb={3} variant='h5'>Pickups</Typography>
                    <Stack gap={2}>
                        {pickups.map((pickup)=>{
                            return <Stack key={pickup.id} flexDirection={'row'} sx={{padding: 2, gap: 4, background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}}>
                                <Stack justifyContent={'center'}>
                                    <Box>
                                        <Checkbox checked={pickupItems.includes(pickup.id)} onChange={()=>{handlePickup(pickup.id)}} />
                                    </Box>
                                </Stack>
                                <Stack>
                                    <Typography><b>Pickup Slot</b></Typography>
                                    <Typography>{moment(pickup.pickupTime).format('Do MMM YYYY h:mm a')}</Typography>
                                    <Typography mt={1}><b>Customer Name</b></Typography>
                                    <Typography>{pickup.user.name}</Typography>
                                    <Typography mt={1}><b>Customer Phone</b></Typography>
                                    <Typography>{pickup.user.phone}</Typography>
                                </Stack>
                                <Stack flex={1} ml={4} flexDirection={'row'} justifyContent={'space-between'}>
                                    {!pickup.pickupStore && <Stack flex={1}>
                                        <Typography><b>Pickup Address</b></Typography>
                                        <Typography>{pickup.address.name}</Typography>
                                        <Typography>{pickup.address.phone}</Typography>
                                        {pickup.address.address.split('\n').map((line, index)=>{
                                            return <Typography key={`${index}`} variant='body'>{line}</Typography>
                                        })}
                                        <Typography>{pickup.address.city}, {pickup.address.state.name} - {pickup.address.pincode}</Typography>
                                    </Stack>}
                                    {pickup.pickupStore && <Stack flex={1}>
                                        <Typography><b>Pickup at Store</b></Typography>
                                        <Typography fontWeight={'bold'}>{pickup.pickupStore.name}</Typography>
                                        {pickup.pickupStore.address.split('\n').map((line, index)=>{
                                            return <Typography key={`${index}`} variant='body'>{line}</Typography>
                                        })}
                                        <Typography>{pickup.pickupStore.city}, {pickup.pickupStore.state.name} - {pickup.pickupStore.pincode}</Typography>
                                    </Stack>}
                                    <Stack gap={2} alignItems={'flex-end'}>
                                        <Link to={`/orders/${pickup.id}`}>
                                            <Button variant='contained' size='small'>Details</Button>
                                        </Link>    
                                        <Button variant='contained' size='small' onClick={()=>{openPickupModal(pickup)}}>Assign Now</Button>
                                        <Button variant='contained' size='small' onClick={()=>{openQueueModal(pickup, "P")}}>Add to Queue</Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        })}
                    </Stack>
                    {!isLoading && pickups.length === 0 && <Stack textAlign={'center'}>
                        <Typography>No Scheduled Pickups</Typography>
                    </Stack>}
                </Stack>
                <Stack flex={1} padding={2}>
                    <Typography mb={3} variant='h5'>Delivery</Typography>
                    <Stack gap={2}>
                        {deliveries.map((delivery)=>{
                            return <Stack key={delivery.id} flexDirection={'row'} sx={{padding: 2, gap: 4, background: '#fff', borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}}>
                                <Stack justifyContent={'center'}>
                                    <Box>
                                        <Checkbox checked={deliveryItems.includes(delivery.id)} onChange={()=>{handleDelivery(delivery.id)}} />
                                    </Box>
                                </Stack>
                                <Stack flex={1}>
                                    <Stack flexDirection={'row'} flex={1}>
                                        <Stack>
                                            <Typography><b>Customer Name</b></Typography>
                                            <Typography>{delivery.user.name}</Typography>
                                            <Typography mt={2}><b>Customer Phone</b></Typography>
                                            <Typography>{delivery.user.phone}</Typography>
                                            <Typography mt={2}><b>Order ID</b></Typography>
                                            <Typography>{delivery.orderId}</Typography>
                                        </Stack>
                                        <Stack flex={1} ml={4} flexDirection={'row'} justifyContent={'space-between'}>
                                            {!delivery.deliveryStore && <Stack flex={1}>
                                                <Typography><b>Delivery Address</b></Typography>
                                                <Typography>{delivery.address.name}</Typography>
                                                <Typography>{delivery.address.phone}</Typography>
                                                {delivery.address.address.split('\n').map((line, index)=>{
                                                    return <Typography key={`${index}`} variant='body'>{line}</Typography>
                                                })}
                                                <Typography>{delivery.address.city}, {delivery.address.state.name} - {delivery.address.pincode}</Typography>
                                                <Typography mt={2}><b>Bag Qrcode</b></Typography>
                                                <Typography>{delivery.deliveryBags.join(', ')}</Typography>
                                            </Stack>}
                                            {delivery.deliveryStore && <Stack flex={1}>
                                                <Typography><b>Delivery at Store</b></Typography>
                                                <Typography fontWeight={'bold'}>{delivery.deliveryStore.name}</Typography>
                                                {delivery.deliveryStore.address.split('\n').map((line, index)=>{
                                                    return <Typography key={`${index}`} variant='body'>{line}</Typography>
                                                })}
                                                <Typography>{delivery.deliveryStore.city}, {delivery.deliveryStore.state.name} - {delivery.deliveryStore.pincode}</Typography>
                                                <Typography mt={2}><b>Bag Qrcode</b></Typography>
                                                <Typography>{delivery.deliveryBags.join(', ')}</Typography>
                                            </Stack>}
                                            <Stack gap={2} alignItems={'flex-end'}>
                                                <Link to={`/orders/${delivery.id}`}>
                                                    <Button variant='contained' size='small'>Details</Button>
                                                </Link>  
                                                {delivery.deliveryBags.length > 0 && <Link to={`${apiUrl}order/deliverytags/${delivery.id}`} target='_blank'>
                                                    <Button variant='contained' size='small'>Print Delivery</Button>
                                                </Link>} 
                                                <Button variant='contained' size='small' onClick={()=>{openQueueModal(delivery, "D")}}>Add to Queue</Button>
                                                <Button variant='contained' size='small' onClick={()=>{openNoteModal(delivery)}}>Delivery Note</Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    {delivery.deliveryNote && <Stack>
                                        <Typography mt={2}><b>NOTE</b></Typography>
                                        {delivery.deliveryNote.split('\n').map((line, index)=>{
                                            return <Typography key={`${index}`} variant='body'>{line}</Typography>
                                        })}
                                    </Stack>}
                                </Stack>
                            </Stack>
                        })}
                    </Stack>
                    {!isLoading && deliveries.length === 0 && <Stack textAlign={'center'}>
                        <Typography>No Deliveries Ready</Typography>
                    </Stack>}
                </Stack>
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} my={2}>
                <LoadingButton variant='contained' loading={isSubmitting} loadingPosition='start' startIcon={<ElectricMoped/>} onClick={readyAssign}>Assign Pickup & Delivery</LoadingButton>
            </Stack>

            <Modal open={pickupModal} onClose={pickupModalClose}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Assign Pickup to Active Rider</Typography>
                        <FormControl fullWidth>
                            <InputLabel>Select Rider</InputLabel>
                            <Select value={selectedActiveRider} onChange={(e)=>{setSelectedActiveRider(e.target.value)}} variant="outlined" disabled={pickupLoading}>
                                {activeRiders.map((rider) => {
                                    return <MenuItem key={rider.id} value={rider.id}>{rider.name}</MenuItem>
                                })}
                            </Select>
                            {pickupLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Riders</Typography>
                            </Stack>}
                        </FormControl>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={pickupSubmitting} loadingPosition='start' startIcon={<AssignmentInd/>} onClick={submitPickup} ><span>Assign Now</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={queueModal} onClose={queueModalClose}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Add Order to Queue</Typography>
                        <FormControl fullWidth>
                            <InputLabel>Select Rider</InputLabel>
                            <Select value={selectedQueueRider} onChange={(e)=>{setSelectedQueueRider(e.target.value)}} variant="outlined" disabled={queueLoading}>
                                {allRiders.map((rider) => {
                                    return <MenuItem key={rider.id} value={rider.id}>{rider.name}</MenuItem>
                                })}
                            </Select>
                            {queueLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                                <ClipLoader size={15} color='#000' />
                                <Typography variant='body'>Loading Riders</Typography>
                            </Stack>}
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Select Time Slot</InputLabel>
                            <Select value={selectedTime} onChange={(e)=>{setSelectedTime(e.target.value)}} variant="outlined" >
                                {timeList.map((time) => {
                                    return <MenuItem key={time.value} value={time.value}>{time.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={queueSubmitting} loadingPosition='start' startIcon={<AssignmentInd/>} onClick={submitQueue} ><span>Assign Now</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={noteModal} onClose={closeNoteModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>Edit Delivery Note</Typography>
                        <TextField label="Note" sx={{marginTop: 2}} value={note} onChange={(e)=>{setNote(e.target.value)}} variant="outlined" fullWidth multiline rows={3}/>
                        <LoadingButton fullWidth variant="contained" color="primary" loading={submittingNote} loadingPosition='start' startIcon={<Note/>} onClick={updateNote}><span>Update Note</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default SchedulePage