import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { countryCode, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { Store } from '@mui/icons-material';
import moment from 'moment';
import { useCommonUI } from '../context/UI';

function StorePage() {

    const {fetchData, postData} = useApiRequest();
    const {openSessions} = useCommonUI();
    const [storeList, setStoreList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);

    const [storeModalOpen, setStoreModalOpen] = useState(false);
    const [newStore, setNewStore] = useState({ name: '', phone: '', address: '', city: '', state: '', pincode: '', password: '', latitude: '', longitude: '', servicePincode: [], type: 'store', parent: 'none' });

    const [storeId, setStoreId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setStoreList([]);
        const resp = await fetchData('store/listall');
        const resp1 = await fetchData('app/states');
        if(resp){
            setStoreList(resp.data);
        }
        if(resp1){
            setStates(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addStore = () =>{
        setNewStore({ name: '', phone: '', address: '', city: '', state: '', pincode: '', password: '', latitude: '', longitude: '', servicePincode: [], type: 'store', parent: 'none' });
        setStoreId(null);
        setStoreModalOpen(true);
    }

    const editStore = (store) =>{
        setNewStore({ name: store.name, phone: store.phone, address: store.address, city: store.city, state: store.state.id, pincode: store.pincode, latitude: store.location.latitude, longitude: store.location.longitude, servicePincode: store.servicePincode, type: store.type, parent: store.parent, password: '' });
        setStoreId(store.id);
        setStoreModalOpen(true);
    }

    const submitStore = async(e) =>{
        e.preventDefault();
        let endpoint = "store/add";
        if(storeId){
            endpoint = "store/edit/"+storeId;
        }

        let obj = {
            ...newStore, 
            location: {
                latitude: newStore.latitude,
                longitude: newStore.longitude
            }
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setStoreModalOpen(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const manageSession = (store) => {
        openSessions(store.id, "store");
    }

    const handleNewStore = (e) => {
        const { name, value } = e.target;
        if(name === "servicePincode"){
            setNewStore({ ...newStore, [name]: value.split(',') });
        }else{
            setNewStore({ ...newStore, [name]: value });
        }
    };

    const handleStoreModalClose = () =>{
        if(isSubmitting){
            return;
        }
        setStoreModalOpen(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Stores</Typography>
                <Button variant='contained' onClick={addStore} >Add Store</Button>
            </Stack>

            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='10%'>
                    <Typography><b>Created</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='20%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='9%'>
                    <Typography><b>Phone</b></Typography>
                </Box>
                <Box width='18%' paddingX={1}>
                    <Typography><b>Address</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>City</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>State</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Pincode</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>Sessions</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {storeList.map((store, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='10%'>
                            <Typography>{moment(store.createdAt).format('Do MMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{store.type==="store"?"Pickup Store":"Processing Center"}</Typography>
                        </Box>
                        <Box width='20%' paddingX={1}>
                            <Typography>{store.name}</Typography>
                        </Box>
                        <Box width='9%'>
                            <Typography>{store.phone}</Typography>
                        </Box>
                        <Box width='18%' paddingX={1}>
                            <Typography>{store.address}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{store.city}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{store.state.name}</Typography>
                        </Box>
                        <Box width='5%'>
                            <Typography>{store.pincode}</Typography>
                        </Box>
                        <Box width='8%'>
                            <Button variant='contained' color='warning' onClick={()=>{manageSession(store)}}>Manage</Button>
                        </Box>
                        <Box width='5%'>
                            <Button variant='contained' color='error' onClick={()=>{editStore(store)}} >Edit</Button>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Stores</Typography>
                </Stack>}
                {storeList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Stores to show</Typography>
                </Stack>}
            </Stack>

           <Modal open={storeModalOpen} onClose={handleStoreModalClose}>
                <Box component="form" sx={{position: 'absolute',top: '10%', bottom: '10%', overflowY: 'auto', left: 'calc(50% - 250px)', width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2} onSubmit={submitStore}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{storeId?"Edit":"Add"} Store</Typography>
                        <TextField label="Name" name='name' value={newStore.name} onChange={handleNewStore} variant="outlined" fullWidth required/>
                        <TextField label="Phone" name='phone' type='number' value={newStore.phone} onChange={handleNewStore} variant="outlined" fullWidth required InputProps={{inputProps: {min: 0}, startAdornment: <InputAdornment position='start'>{countryCode}</InputAdornment>}}/>

                        <Box>
                            <TextField label="Password" name='password' value={newStore.password} onChange={handleNewStore} variant="outlined" fullWidth />
                            {storeId && <Typography fontSize={12}><i>Keep blank if not changed</i></Typography>}
                        </Box>

                        <TextField label="Address" name='address' value={newStore.address} onChange={handleNewStore} variant="outlined" fullWidth multiline rows={4} required/>
                        <TextField label="City" name='city' value={newStore.city} onChange={handleNewStore} variant="outlined" fullWidth required/>
                        <FormControl fullWidth required>
                            <InputLabel>State</InputLabel>
                            <Select name="state" value={newStore.state} onChange={handleNewStore} variant="outlined">
                                {states.map((state) => {
                                    return <MenuItem key={state} value={state.id}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pincode" name='pincode' type='number' value={newStore.pincode} onChange={handleNewStore} variant="outlined" fullWidth required/>

                        <TextField label="Latitude" name='latitude' type='number' value={newStore.latitude} onChange={handleNewStore} variant="outlined" fullWidth required/>
                        <TextField label="Longitude" name='longitude' type='number' value={newStore.longitude} onChange={handleNewStore} variant="outlined" fullWidth required/>

                        <FormControl fullWidth required>
                            <InputLabel>Type</InputLabel>
                            <Select name="type" value={newStore.type} onChange={handleNewStore} variant="outlined" disabled={storeId?true:false}>
                                <MenuItem value={"center"}>Processing Center</MenuItem>
                                <MenuItem value={"store"}>Pickup Store</MenuItem>
                            </Select>
                        </FormControl>

                        {newStore.type === "store" && <FormControl fullWidth>
                            <InputLabel>Parent Processing Center</InputLabel>
                            <Select name="parent" value={newStore.parent?newStore.parent:''} onChange={handleNewStore} variant="outlined">
                                <MenuItem value={"none"}>None</MenuItem>
                                {storeList.map((store) => {
                                    if(store.type === "store"){
                                        return null;
                                    }
                                    return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>}

                        {newStore.type === "center" && <TextField label="Service Pincodes" name='servicePincode' value={newStore.servicePincode.join(',')} onChange={handleNewStore} variant="outlined" fullWidth multiline rows={4}/>}

                        <LoadingButton fullWidth type="submit" variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<Store/>}><span>{storeId?"Update":"Add"} Store</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default StorePage