import React, { useCallback, useEffect, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Close, SearchTwoTone } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { DateRangePicker } from 'rsuite';

function RiderOrdersPage() {

    const {riderId} = useParams();
    const {postData, fetchData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const [riderData, setRiderData] = useState(null);
    const [page, setPage] = useState(2);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedType, setSelectedType] = useState('');
    const [bodyData, setBodyData] = useState({type: '', start: 0, end: 0});
    const [totalDocs, setTotalDocs] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('rider/orders/'+riderId+'/'+page, body);
        if(resp){
            setOrders((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData, riderId])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setOrders([]);
            const resp = await postData('rider/orders/'+riderId+'/1', {type: '', start: 0, end: 0});
            const resp1 = await fetchData('rider/info/'+riderId);
            if(resp1){
                setRiderData(resp1.data);
            }
            if(resp){
                setOrders(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData, fetchData, riderId]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({type: '', start: 0, end: 0});
        setOrders([]);
        setSelectedType('');
        setSelectedDate(null);
        getData(1, {type: '', start: 0, end: 0});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            type: selectedType,
            start,
            end
        }
        setBodyData(body);
        setOrders([]);
        getData(1, body);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant='h4'>Rider Job History ({totalDocs})</Typography>
                {riderData && <Typography variant='h4'>{riderData.name}</Typography>}
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <Box width={400}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select value={selectedType} onChange={(e)=>{setSelectedType(e.target.value)}} >
                            <MenuItem value='P'>Pickup</MenuItem>
                            <MenuItem value='D'>Delivery</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Job Date</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Type</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Order Id</b></Typography>
                </Box>
                <Box width='30%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Details</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} gap={1} height='calc(100vh - 338px)' overflow='auto' >
                {orders.map((job, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment(job.created).format('Do MMM yyyy hh:mm a')}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{job.type==="P"?"Pickup":"Delivery"}</Typography>
                        </Box>
                        <Box width='15%'>
                            {job.order && <Typography>{job.order.orderId}</Typography>}
                        </Box>
                        <Box width='30%'>
                            {job.order && <Typography>{job.order.address.name}</Typography>}
                        </Box>
                        <Box width='20%'>
                            {job.order && <Typography>{job.order.address.phone}</Typography>}
                        </Box>
                        <Box width='10%'>
                            {job.order && <Link to={`/orders/${job.order.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>}
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Jobs</Typography>
                </Stack>}
                {orders.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Jobs to show</Typography>
                </Stack>}
            </Stack>
            {!isLoading && page && <Stack alignItems={'center'}>
                <Box>
                    <Button variant='contained' onClick={()=>{getData(page, bodyData)}}>Load More Orders</Button>
                </Box>
            </Stack>}
        </Stack>
    )
}

export default RiderOrdersPage