import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { Category } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

function CategoryPage() {

    const {serviceId} = useParams();
    const {fetchData, postData} = useApiRequest();
    const [serviceData, setServiceData] = useState({name: '', id: null});
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [categoryName, setCategoryName] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [categoryModal, setCategoryModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setCategoryList([]);
        const resp = await fetchData('service/info/'+serviceId);
        const resp1 = await fetchData('service/categories/'+serviceId);
        if(resp && resp1){
            setServiceData(resp.data);
            setCategoryList(resp1.data);
        }
        setIsLoading(false);
    },[fetchData, serviceId]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addCategory = () =>{
        setCategoryName('');
        setCategoryId(null);
        setCategoryModal(true);
    }

    const editCategory = (category) => {
        setCategoryId(category.id);
        setCategoryName(category.name);
        setCategoryModal(true);
    }

    const closeCategoryModal = () =>{
        if(isSubmitting){
            return;
        }
        setCategoryModal(false);
    }

    const submitCategory = async() =>{
        if(categoryName === ""){
            return;
        }
        let endpoint = "service/category";
        if(categoryId){
            endpoint = "service/category/edit/"+categoryId;
        }
        let obj = {
            name: categoryName.trim(),
            sid: serviceId
        }
        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setCategoryModal(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Categories for {serviceData.name}</Typography>
                <Button variant='contained' onClick={addCategory} >Add Category</Button>
            </Stack>

            <Stack mt={3} p={2} pt={0} height='calc(100vh - 240px)' overflow='auto' gap={1}>
                {categoryList.map((category, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' justifyContent={'space-between'} sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>

                        <Typography>{category.name}</Typography>
                        <Button variant='contained' color='error' onClick={()=>{editCategory(category)}} >Edit</Button>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Categories</Typography>
                </Stack>}
                {categoryList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Category to show</Typography>
                </Stack>}
            </Stack>
            
            <Modal open={categoryModal} onClose={closeCategoryModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{categoryId?'Edit':'Add'} Category</Typography>
                        <TextField label="Category Name" value={categoryName} onChange={(e)=>{setCategoryName(e.target.value)}} variant="outlined" fullWidth/>

                        <LoadingButton fullWidth onClick={submitCategory} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<Category/>}><span>{categoryId?'Update':'Add'} Category</span></LoadingButton>
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default CategoryPage