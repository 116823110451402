import { CssBaseline, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Login from './pages/Login';
import { useCallback, useEffect } from 'react';
import { useApiRequest } from './store/Common';
import Navbar from './components/Navbar';
import Empty from './pages/Empty';
import { updateLogin, updateName, updatePhone, updateType } from './store/slices/configSlice';
import OrdersPage from './pages/OrdersPage';
import OrderDetails from './pages/OrderDetails';
import CustomerPage from './pages/CustomerPage';
import CustomerDetails from './pages/CustomerDetails';
import SubscriptionsPage from './pages/SubscriptionsPage';
import ExpensePage from './pages/ExpensePage';
import PlansPage from './pages/PlansPage';
import WorkerPage from './pages/WorkerPage';
import RiderPage from './pages/RiderPage';
import RiderOrdersPage from './pages/RiderOrdersPage';
import PartnerPage from './pages/PartnerPage';
import PartnerOrdersPage from './pages/PartnerOrdersPage';
import StorePage from './pages/StorePage';
import UserPage from './pages/UserPage';
import ServicePage from './pages/ServicePage';
import CategoryPage from './pages/CategoryPage';
import ClothPage from './pages/ClothPage';
import ClothItemPage from './pages/ClothItemPage';
import Dashboard from './pages/Dashboard';
import PickupPage from './pages/PickupPage';
import SchedulePage from './pages/SchedulePage';
import ActiveJobs from './pages/ActiveJobs';
import QueuePage from './pages/QueuePage';
import SubPurchasePage from './pages/SubPurchasePage';
import SocietyCustomerPage from './pages/SocietyCustomerPage';

function App({loggedIn, type}) {

    const dispatch = useDispatch();
    const {fetchData} = useApiRequest();

    const getConfig = useCallback(async() =>{
        let session = localStorage.getItem('session');
        if(!session){
            dispatch(updateLogin(false));
        }else{
            const resp = await fetchData('app/config');
            if(resp){
                dispatch(updateName(resp.data.name));
                dispatch(updatePhone(resp.data.phone));
                dispatch(updateType(resp.data.type));
                dispatch(updateLogin(true));
            }
        }
    },[dispatch, fetchData]);

    useEffect(()=>{
        getConfig();
    },[getConfig]);

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
            primary: {main: '#d9166f'}
        }
    });

    return(
        <ThemeProvider theme={lightTheme}>
            <CssBaseline/>
            {loggedIn === null && <Stack sx={{height: '100vh', color: '#000', justifyContent: 'center', alignItems: 'center', gap:5}}>
                <img src='/assets/dryfi.png' alt='Dryfi' width='300px' />
                <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='h6'>Loading Dryfi Admin</Typography>
                </Stack>
            </Stack>}

            {loggedIn === false && <Stack sx={{height: '100vh'}}>
                <Login />
            </Stack>}

            {loggedIn && <Stack sx={{height: '100vh'}}>
                <Navbar/>
                <Stack sx={{flex: 1}}>
                    <Routes>
                        <Route path='/' element={<Dashboard/>} />
                        <Route path='/orders' element={<OrdersPage/>} />
                        <Route path='/customers' element={<CustomerPage/>} />
                        <Route path='/subscriptions' element={<SubscriptionsPage/>} />
                        {type === "admin" && <Route path='/expenses' element={<ExpensePage/>} />}
                        {type === "admin" && <Route path='/riders' element={<RiderPage/>} />}
                        <Route path='/customers/:customerId' element={<CustomerDetails />}/>
                        <Route path='/orders/:orderId' element={<OrderDetails />}/>
                        {type === "admin" && <Route path='/plans' element={<PlansPage />}/>}
                        {type === "admin" && <Route path='/workers' element={<WorkerPage />}/>}
                        {type === "admin" && <Route path='/partners' element={<PartnerPage />}/>}
                        {type === "admin" && <Route path='/stores' element={<StorePage />}/>}
                        {type === "admin" && <Route path='/users' element={<UserPage />}/>}
                        {type === "admin" && <Route path='/services' element={<ServicePage />}/>}
                        <Route path='/schedule' element={<PickupPage />}/>
                        <Route path='/pickup' element={<SchedulePage />}/>
                        <Route path='/jobs' element={<ActiveJobs />}/>
                        <Route path='/queue' element={<QueuePage />}/>
                        <Route path='/purchases' element={<SubPurchasePage />}/>
                        {type === "admin" && <Route path='/riders/:riderId' element={<RiderOrdersPage />}/>}
                        {type === "admin" && <Route path='/partner/:partnerId' element={<PartnerOrdersPage />}/>}
                        {type === "admin" && <Route path='/customer/:partnerId' element={<SocietyCustomerPage />}/>}
                        {type === "admin" && <Route path='/category/:serviceId' element={<CategoryPage />}/>}
                        {type === "admin" && <Route path='/cloth/:serviceId' element={<ClothPage />}/>}
                        {type === "admin" && <Route path='/clothitem/:serviceId' element={<ClothItemPage />}/>}
                        <Route path='*' element={<Empty />}/>
                    </Routes>
                </Stack>
            </Stack>}

        </ThemeProvider>
    )
}

const mapStateToProps = state => ({
    loggedIn: state.configs.loggedIn,
    type: state.configs.type
});
export default connect(mapStateToProps)(App);
