import { AdminPanelSettings, Dashboard, DeliveryDining, DryCleaning, Engineering, ExpandMore, Group, Handshake, LocalAtm, Logout, Queue, Schedule, Schema, Store, Subscriptions, SupervisorAccount, ViewList, Work } from '@mui/icons-material';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useCommonUI } from '../context/UI';
import { useApiRequest } from '../store/Common';
import { updateLogin } from '../store/slices/configSlice';

function Navbar({storeName, type}) {

    const location = useLocation();
    const dispatch = useDispatch();
    const {fetchData} = useApiRequest();
    const {openSnackbar, openDialog} = useCommonUI();
    const isActive = (path) => location.pathname === path?'mactive':'';
    const [isLogout, setIsLogout] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const logout = () =>{
        if(isLogout){
            openSnackbar('Logout in progress', 'error');
        }
        openDialog("Logout?", "Are you sure you want to Logout?", confirmLogout, null);
    }
    const confirmLogout = async() =>{
        openSnackbar('Logging you out', 'info');
        setIsLogout(true);
        const resp = await fetchData('app/logout');
        if(resp){
            dispatch(updateLogin(false));
            localStorage.removeItem('session');
            openSnackbar("Logged Out", 'success');
        }
        setIsLogout(false);
    }

    const openMenu = (event) =>{
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box className='navbar'>
            <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingX: 3}}>

                <Stack flexDirection='row' gap={1} alignItems='center' paddingY='10px'>
                    <img src='/assets/dryfi.png' alt='Dryfi' height='70px'/>
                </Stack>

                <Stack sx={{flexDirection: 'row', gap: 2}}>
                    <Link className={'menu '+isActive('/')} to='/'>
                        <Dashboard fontSize='10'/>
                        <Typography fontWeight='bold'>Dashboard</Typography>
                    </Link>
                    <Link className={'menu '+isActive('/schedule')} to='/schedule'>
                        <Schedule fontSize='10'/>
                        <Typography fontWeight='bold'>Schedule</Typography>
                    </Link>
                    <Link className={'menu '+isActive('/orders')} to='/orders'>
                        <ViewList fontSize='10'/>
                        <Typography fontWeight='bold'>Orders</Typography>
                    </Link>
                    <Link className={'menu '+isActive('/customers')} to='/customers'>
                        <Group fontSize='10'/>
                        <Typography fontWeight='bold'>Customers</Typography>
                    </Link>
                    <Link className={'menu '+isActive('/subscriptions')} to='/subscriptions'>
                        <Subscriptions fontSize='10'/>
                        <Typography fontWeight='bold'>Subscriptions</Typography>
                    </Link>
                    {type === "admin" && <Link className={'menu '+isActive('/expenses')} to='/expenses'>
                        <LocalAtm fontSize='10'/>
                        <Typography fontWeight='bold'>Expenses</Typography>
                    </Link>}
                    <Link className={'menu '+isActive('/pickup')} to='/pickup'>
                        <DeliveryDining fontSize='10'/>
                        <Typography fontWeight='bold'>Pickup & Delivery</Typography>
                    </Link>
                    <Link className={'menu '+isActive('/jobs')} to='/jobs'>
                        <Work fontSize='10'/>
                        <Typography fontWeight='bold'>Active Jobs</Typography>
                    </Link>
                    <Link className={'menu '+isActive('/queue')} to='/queue'>
                        <Queue fontSize='10'/>
                        <Typography fontWeight='bold'>Queue</Typography>
                    </Link>
                    {type === "admin" && <Stack onClick={openMenu} flexDirection={'row'} alignItems={'center'} gap={1}>
                        <AdminPanelSettings fontSize='10'/>
                        <Typography fontWeight='bold'>Admin</Typography>
                        <ExpandMore />
                    </Stack>}
                    <Stack className='menu' sx={{cursor: 'pointer'}} flexDirection={'row'} alignItems={'center'} gap={0.5} onClick={logout}>
                        <Logout fontSize='10'/>
                        <Typography fontWeight='bold'>Logout</Typography>
                    </Stack>
                </Stack>

                <Typography variant='h5'><b>{storeName}</b></Typography>
            </Stack>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/workers')} to='/workers'>
                        <Engineering fontSize='10'/>
                        <Typography fontWeight='bold'>Workers</Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/riders')} to='/riders'>
                        <DeliveryDining fontSize='10'/>
                        <Typography fontWeight='bold'>Riders</Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/partners')} to='/partners'>
                        <Handshake fontSize='10'/>
                        <Typography fontWeight='bold'>Partners</Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/stores')} to='/stores'>
                        <Store fontSize='10'/>
                        <Typography fontWeight='bold'>Stores</Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/users')} to='/users'>
                        <SupervisorAccount fontSize='10'/>
                        <Typography fontWeight='bold'>Users</Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/plans')} to='/plans'>
                        <Schema fontSize='10'/>
                        <Typography fontWeight='bold'>Plans</Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={'menu '+isActive('/services')} to='/services'>
                        <DryCleaning fontSize='10'/>
                        <Typography fontWeight='bold'>Services</Typography>
                    </Link>
                </MenuItem>
            </Menu>
        </Box>
    )
}

const mapStateToProps = state => ({
    storeName: state.configs.storeName,
    type: state.configs.type
});
export default connect(mapStateToProps)(Navbar)