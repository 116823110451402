import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { countryCode, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { LoadingButton } from '@mui/lab';
import { TwoWheeler } from '@mui/icons-material';
import moment from 'moment';
import { useCommonUI } from '../context/UI';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';

function RiderPage() {

    const {fetchData, postData} = useApiRequest();
    const {openSessions} = useCommonUI();
    const {afterToday} = DateRangePicker;
    const [riderList, setRiderList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [stores, setStores] = useState([]);

    const [riderModal, setRiderModal] = useState(false);
    const [riderId, setRiderId] = useState(null);
    const [riderName, setRiderName] = useState('');
    const [riderPhone, setRiderPhone] = useState('');
    const [riderStore, setRiderStore] = useState('');
    const [riderStatus, setRiderStatus] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [reportModal, setReportModal] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [reportRider, setReportRider] = useState(null);
    const [reportDate, setReportDate] = useState(null);
    const [reportList, setReportList] = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalTrip, setTotalTrip] = useState(0);

    const getOneData = useCallback(async() =>{
        setIsLoading(true);
        setRiderList([]);
        const resp = await fetchData('rider/list');
        const resp1 = await fetchData('store/listcenter');
        if(resp){
          setRiderList(resp.data);
        }
        if(resp1){
            setStores(resp1.data);
        }
        setIsLoading(false);
    },[fetchData]);

    useEffect(()=>{
        getOneData();
    },[getOneData]);

    const addRider = () =>{
        setRiderId(null);
        setRiderName('');
        setRiderPhone('');
        setRiderStore('');
        setRiderStatus(true);
        setRiderModal(true);
    }

    const editRider = (rider) =>{
        setRiderId(rider.id);
        setRiderName(rider.name);
        setRiderPhone(rider.phone);
        setRiderStore(rider.store.id);
        setRiderStatus(rider.status);
        setRiderModal(true);
    }

    const closeRiderModal = () =>{
        if(isSubmitting){
            return;
        }
        setRiderModal(false);
    }

    const submitRider = async() =>{
        let endpoint = "rider/add";
        if(riderId){
            endpoint = "rider/edit/"+riderId;
        }
        if(riderName.trim() === "" || riderPhone === "" || riderStore === ""){
            return;
        }
        let obj = {
            name: riderName.trim(),
            phone: riderPhone,
            store: riderStore,
            status: riderStatus
        }

        setIsSubmitting(true);
        const resp = await postData(endpoint, obj);
        if(resp){
            setRiderModal(false);
            getOneData();
        }
        setIsSubmitting(false);
    }

    const manageSession = (rider) => {
        openSessions(rider.id, "rider");
    }

    const manageReport = (rider) => {
        setReportRider(rider);
        setReportList([]);
        setReportDate(null);
        setReportModal(true);
    }

    const closeReportModal = () =>{
        if(reportLoading){
            return;
        }
        setReportModal(false);
    }

    const getReport = async() =>{
        setTotalDistance(0);
        setTotalTrip(0);

        let start = 0;
        let end = 0;
        if(reportDate && reportDate.length === 2){
            start = reportDate[0].getTime()
            end = reportDate[1].setHours(23, 59, 59, 999)
        }else{
            return;
        }

        setReportLoading(true);
        const resp = await postData('rider/report',{rid: reportRider.id, start, end});
        if(resp){
            setReportList(resp.data);
            let distance = 0;
            let trip = 0;
            for(const rpr of resp.data){
                distance += rpr.distance;
                trip += rpr.trips;
            }
            setTotalDistance(distance);
            setTotalTrip(trip);
        }
        setReportLoading(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Riders</Typography>
                <Button variant='contained' onClick={addRider} >Add Rider</Button>
            </Stack>

            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='15%' paddingX={1}>
                    <Typography><b>Name</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Phone</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Store</b></Typography>
                </Box>
                <Box width='6%'>
                    <Typography><b>Status</b></Typography>
                </Box>
                <Box width='6%'>
                    <Typography><b>Online</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>Report</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Session</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Edit</b></Typography>
                </Box>
                <Box width='5%'>
                    <Typography><b>Orders</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 220px)' overflow='auto' gap={1}>
                {riderList.map((rider, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment(rider.created).format('Do MMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='15%' paddingX={1}>
                            <Typography>{rider.name}</Typography>
                        </Box>
                        <Box width='15%'>
                            <Typography>{rider.phone}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{rider.store.name}</Typography>
                        </Box>
                        <Box width='6%'>
                            {rider.status && <Typography color={'green'}>Active</Typography>}
                            {!rider.status && <Typography color={'red'}>Inactive</Typography>}
                        </Box>
                        <Box width='6%'>
                            {rider.online && <Typography color={'green'}>Online</Typography>}
                            {!rider.online && <Typography color={'red'}>Offline</Typography>}
                        </Box>
                        <Box width='8%'>
                            <Button variant='contained' onClick={()=>{manageReport(rider)}}>Report</Button>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='warning' onClick={()=>{manageSession(rider)}}>Manage</Button>
                        </Box>
                        <Box width='10%'>
                            <Button variant='contained' color='error' onClick={()=>{editRider(rider)}} >Edit</Button>
                        </Box>
                        <Box width='5%'>
                            <Link to={"/riders/"+rider.id}>
                              <Button variant='contained'>Orders</Button>
                            </Link>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Riders</Typography>
                </Stack>}
                {riderList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Riders to show</Typography>
                </Stack>}
            </Stack>

            <Modal open={riderModal} onClose={closeRiderModal}>
                <Box sx={{position: 'absolute',top: '20%', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    <Stack gap={2}>
                        <Typography variant='h6'>{riderId?'Edit':'Add'} Rider</Typography>
                        <TextField label="Rider Name" value={riderName} onChange={(e)=>{setRiderName(e.target.value)}} variant="outlined" fullWidth/>

                        <TextField label="Rider Phone" type='number' value={riderPhone} onChange={(e)=>{setRiderPhone(e.target.value)}} variant="outlined" fullWidth InputProps={{inputProps: {min: 0}, startAdornment: <InputAdornment position='start'>{countryCode}</InputAdornment>}}/>

                        <FormControl fullWidth>
                            <InputLabel>Store</InputLabel>
                            <Select value={riderStore} onChange={(e)=>{setRiderStore(e.target.value)}}>
                                {stores.map(store=>{
                                    return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Rider Status</InputLabel>
                            <Select value={riderStatus} onChange={(e)=>{setRiderStatus(e.target.value)}} variant="outlined">
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>

                        <LoadingButton fullWidth onClick={submitRider} variant="contained" color="primary" loading={isSubmitting}  loadingPosition='start' startIcon={<TwoWheeler/>}><span>{riderId?'Update':'Add'} Rider</span></LoadingButton>
                    </Stack>
                </Box>
           </Modal>

           <Modal open={reportModal} onClose={closeReportModal}>
                <Box sx={{position: 'absolute',top: '20%', bottom: '20%', overflowY: 'auto', left: 'calc(50% - 250px)',width: 500,bgcolor: 'background.paper', borderRadius: 5}} padding={2}>
                    {reportRider && <Stack>
                        <Typography variant='h6'>{reportRider.name}</Typography>
                    </Stack>}
                    <Stack flexDirection={'row'} justifyContent={'space-between'} py={2}>
                        <Box width={350}>
                            <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={reportDate} onChange={setReportDate} />
                        </Box>
                        <Button variant='contained' onClick={getReport}>Get Report</Button>
                    </Stack>
                    {reportLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <ClipLoader size={15} color='#000' />
                        <Typography variant='body'>Loading Report</Typography>
                    </Stack>}
                    {reportList.length === 0 && !reportLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant='body'>No Data to show</Typography>
                    </Stack>}
                    <Stack gap={2}>
                        {reportList.map(report=>{
                            return <Stack sx={{background: '#f1f1f1'}} p={1.5} borderRadius={5}>
                                <Typography><b>Date: </b>{moment(report.date).format('Do MMM YYYY')}</Typography>
                                <Typography><b>Distance: </b>{report.distance} Metres</Typography>
                                <Typography><b>Trips: </b>{report.trips}</Typography>
                            </Stack>
                        })}
                        {reportList.length > 0 && <Stack sx={{background: '#f1f1f1'}} p={1.5} borderRadius={5}>
                            <Typography><b>TOTAL</b></Typography>
                            <Typography><b>Distance: {totalDistance} Metres</b></Typography>
                            <Typography><b>Trips: {totalTrip}</b></Typography>
                        </Stack>}
                    </Stack>
                </Box>
            </Modal>

        </Stack>
    )
}

export default RiderPage