import { Close, SearchTwoTone } from '@mui/icons-material'
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite'
import { currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';

function Dashboard() {

    const {fetchData, postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const [isLoading, setIsLoading] = useState(false);
    const [orderStat, setOrderStat] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState('all');

    const getOrderData = useCallback(async(body) =>{
        setIsLoading(true);
        setOrderStat(null);
        const resp = await postData('stat/order', body);
        if(resp){
            setOrderStat(resp.data);
        }
        setIsLoading(false);
    },[postData])

    useEffect(()=>{
        const getStore = async() =>{
            const resp1 = await fetchData('store/listcenter');
            if(resp1){
                setStores(resp1.data);
            }
        }
        getStore();
    },[fetchData]);

    useEffect(()=>{
        getOrderData({store: 'all', start: 0, end: 0});
    },[getOrderData]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSelectedStores('all');
        setSelectedDate(null);
        getOrderData({store: 'all', start: 0, end: 0});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            store: selectedStores,
            start,
            end
        }
        getOrderData(body);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <Box width={450}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <FormControl fullWidth>
                        <InputLabel>Store</InputLabel>
                        <Select value={selectedStores} onChange={(e)=>{setSelectedStores(e.target.value)}} >
                            <MenuItem value={'all'}>{'All Stores'}</MenuItem>
                            {stores.map(store=>{
                                return <MenuItem key={store.id} value={store.id}>{store.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Data</Typography>
            </Stack>}
            {orderStat && <Grid container>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Sales</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalSales + orderStat.subPurchase).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Payment Received</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalPayment+ orderStat.subPurchase).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Payment Received</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalPayment}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalValue}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Sales</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalSales}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Paid</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalPaid}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Dues</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalUnpaid}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalOrders}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Processing Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalPendingOrders}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Completed Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalCompletedOrders}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Average Order Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalValue/orderStat.totalOrders).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscriptions</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalSubsCount}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Active Subscriptions</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.activeSubsCount} </Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscripton Purchases</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalSubPurchases}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Subscripton Purchase Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.subPurchase}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Expense Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {parseFloat(orderStat.totalExpense).toFixed(2)}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Expenses</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.expenseCount}</Typography>
                    </Stack>
                </Grid>
                <Grid item lg={3}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total New Customers</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.customers}</Typography>
                    </Stack>
                </Grid>
            </Grid>}
        </Stack>
    )
}

export default Dashboard