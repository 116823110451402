import { Close, SearchTwoTone } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';

function SocietyCustomerPage() {

    const {partnerId} = useParams();
    const {fetchData, postData} = useApiRequest();
    const [searchTerm, setSearchTerm] = useState('');
    const [bodyData, setBodyData] = useState({search: '', sort: ''});
    const [sort, setSort] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [partnerData, setPartnerData] = useState(null);


    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSearchTerm('');
        setSort('');
        setBodyData({search: '', sort: ''});
        setCustomerList([]);
        getData(1, {search: '', sort: ''});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        setBodyData({search: searchTerm, sort: sort});
        setCustomerList([]);
        getData(1, {search: searchTerm, sort: sort});
    }

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('customer/partner/'+partnerId+'/'+page, body);
        if(resp){
            setCustomerList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData, partnerId])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setCustomerList([]);
            const resp = await postData('customer/partner/'+partnerId+'/1', {search: '', sort: ''});
            const resp2 = await fetchData('partner/info/'+partnerId);
            if(resp2){
                setPartnerData(resp2.data);
            }
            if(resp){
                setCustomerList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData, fetchData, partnerId]);

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && customerList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, customerList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);


    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Society Customers ({totalDocs})</Typography>
                {partnerData && <Typography variant='h4'>{partnerData.name}</Typography>}
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <TextField fullWidth variant='outlined' label='Customer Name/Phone' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                    <FormControl fullWidth>
                        <InputLabel>Sort</InputLabel>
                        <Select value={sort} onChange={(e)=>{setSort(e.target.value)}} >
                            <MenuItem value='wasc'>Wallet Ascending</MenuItem>
                            <MenuItem value='wdsc'>Wallet Descending</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='20%'>
                    <Typography><b>Joined</b></Typography>
                </Box>
                <Box width='25%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='25%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Wallet</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Details</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {customerList.map((customer, index)=>{
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='20%'>
                            <Typography>{moment.utc(customer.createdAt).local().format('Do MMMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='25%'>
                            <Typography>{customer.name}</Typography>
                        </Box>
                        <Box width='25%'>
                            <Typography>{customer.phone}</Typography>
                        </Box>
                        <Box width='20%'>
                            <Typography>{customer.wallet}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Link to={`/customers/${customer.id}`}>
                                <Button variant='contained' size='small'>Details</Button>
                            </Link>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Customers</Typography>
                </Stack>}
                {customerList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Customers to show</Typography>
                </Stack>}
            </Stack>
            
        </Stack>
    )
}

export default SocietyCustomerPage